// AdSenseAd.tsx
import React, { useEffect } from "react";

interface AdSenseAdProps {
  adClient: string;
  adSlot: string;
  style: React.CSSProperties;
  format: string;
  fullWidthResponsive: string;
}

const AdSenseAd: React.FC<AdSenseAdProps> = ({
  adClient,
  adSlot,
  style,
  format,
  fullWidthResponsive,
}) => {
  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch {}
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", ...style }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-format={format}
      data-full-width-responsive={fullWidthResponsive}
    ></ins>
  );
};

export default AdSenseAd;
