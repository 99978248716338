import React from "react";

const YouTubeIcon = ({ size = 24, color = "#FF0000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
    >
      <path d="M23.4993 6.7998C23.4993 6.7998 23.3393 5.1573 22.5693 4.3683C21.6493 3.4043 20.6993 3.3998 20.2693 3.3398C17.0793 3.0998 11.9993 3.0998 11.9993 3.0998H11.9793C11.9793 3.0998 6.89934 3.0998 3.70934 3.3398C3.27934 3.3998 2.32934 3.4043 1.40934 4.3683C0.63934 5.1573 0.47934 6.7998 0.47934 6.7998C0.47934 6.7998 0.31934 8.6398 0.31934 10.4798V13.5198C0.31934 15.3598 0.47934 17.1998 0.47934 17.1998C0.47934 17.1998 0.63934 18.8423 1.40934 19.6313C2.32934 20.5953 3.46934 20.5798 3.96934 20.6498C5.60934 20.7698 11.9993 20.7998 11.9993 20.7998C11.9993 20.7998 17.0793 20.7698 20.2693 20.5298C20.6993 20.4698 21.6493 20.4643 22.5693 19.5003C23.3393 18.7113 23.4993 17.0688 23.4993 17.0688C23.4993 17.0688 23.6593 15.2288 23.6593 13.3888V10.3488C23.6593 8.50877 23.4993 6.7998 23.4993 6.7998ZM9.74934 15.2398V8.83984L15.7493 12.0398L9.74934 15.2398Z" />
    </svg>
  );
};

export default YouTubeIcon;
