export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID!;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (typeof window !== "undefined" && window.gtag) {
    window?.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events

export const event = (action: string, options: any, siteName: string) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", action, { ...options, siteName });
  }
};
