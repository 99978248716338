import React from "react";

interface MenuButtonProps {
  label: string;
  subLabel?: string;
  href: string;
  onClick?: () => void;
  icon?: JSX.Element;
  className?: string;
  disabled?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  label,
  subLabel,
  href,
  onClick,
  icon,
  className,
  disabled = false,
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (disabled) {
      event.preventDefault();
    } else if (onClick) {
      event.preventDefault();
      onClick();
    }
  };

  const commonClasses = `flex flex-col items-center justify-center bg-white p-5 shadow rounded-[12px] m-[5%] w-[90%] bg-[#F1F3F5] mx-auto ${className} ${
    disabled ? "opacity-90 cursor-not-allowed" : ""
  }`;

  const content = (
    <>
      {icon && <div className="mb-3">{icon}</div>}
      <div className="text-[16px] font-[500] text-gray-900 text-center">
        {label}
      </div>
      {subLabel && (
        <div className="text-sm text-gray-900 text-center">{subLabel}</div>
      )}
    </>
  );

  return href ? (
    <a href={href} className={commonClasses} onClick={handleClick}>
      {content}
    </a>
  ) : (
    <div className={commonClasses}>{content}</div>
  );
};

export default MenuButton;
