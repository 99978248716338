import { useState, useEffect } from "react";

interface Translations {
  [key: string]: string;
}

interface UseTranslationHook {
  translate: (key: string) => string;
}

export function useTranslation(language: string = "en"): UseTranslationHook {
  const [currentTranslations, setCurrentTranslations] = useState<Translations>(
    {}
  );

  useEffect(() => {
    async function loadTranslations(lang: string) {
      try {
        const translations = await import(`../translations/${lang}.json`);
        setCurrentTranslations(translations.default);
      } catch (error) {
        console.error("Could not load translations for language:", lang, error);
        setCurrentTranslations({});
      }
    }

    loadTranslations(language);
  }, [language]);

  const translate = (key: string): string => {
    return currentTranslations[key] || key;
  };

  return { translate };
}
