import React from "react";

const TripAdvisorIcon = ({ size = 24, color = "#00AF87" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <path
        fill="#FFE082"
        d="M24,29C6.9,29-3.1,23.7,3.2,18C8.7,13,16,10,24,10c7.2,0,13.8,2.4,19,6.5C50.3,22.2,41.1,29,24,29z"
      ></path>
      <path
        fill="#FFF"
        d="M23,26c0,6.1-4.9,11-11,11S1,32.1,1,26s4.9-11,11-11S23,19.9,23,26z M36,15c-6.1,0-11,4.9-11,11s4.9,11,11,11s11-4.9,11-11S42.1,15,36,15z"
      ></path>
      <path fill="#F44336" d="M12 24A2 2 0 1 0 12 28A2 2 0 1 0 12 24Z"></path>
      <path fill="#00C853" d="M36 24A2 2 0 1 0 36 28A2 2 0 1 0 36 24Z"></path>
      <path
        fill="#37474F"
        d="M12,17c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S7,17,12,17 M12,14C5.4,14,0,19.4,0,26c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,19.4,18.6,14,12,14L12,14z"
      ></path>
      <path
        fill="#37474F"
        d="M12 22c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4S9.8 22 12 22M12 20c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6S15.3 20 12 20L12 20zM36 17c5 0 9 4 9 9s-4 9-9 9-9-4-9-9S31 17 36 17M36 14c-6.6 0-12 5.4-12 12 0 6.6 5.4 12 12 12 6.6 0 12-5.4 12-12C48 19.4 42.6 14 36 14L36 14z"
      ></path>
      <path
        fill="#37474F"
        d="M36 22c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4S33.8 22 36 22M36 20c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6S39.3 20 36 20L36 20zM24 26L21 33 24 37zM24 26L27 33 24 37zM36 14h12c0 0-3.1 3.8-3 7C42.1 16 36 14 36 14zM12 14H0c0 0 3.1 3.8 3 7C5.9 16 12 14 12 14z"
      ></path>
      <path
        fill="#37474F"
        d="M24,10c-6.3,0-12.2,1.9-17.1,5.2C8.4,14.4,10.2,14,12,14c0.4,0,0.8,0,1.3,0.1c3.4-1.4,7-2.1,10.7-2.1c3.7,0,7.4,0.7,10.7,2.1c0.4,0,0.8-0.1,1.3-0.1c1.8,0,3.6,0.4,5.1,1.2C36.2,11.9,30.3,10,24,10z"
      ></path>
    </svg>
  );
};

export default TripAdvisorIcon;
