import React from "react";

interface LanguagePopupProps {
  currentLanguage: string;
  isOpen: boolean;
  togglePopup: () => void;
  languages: string[];
  siteName: string;
}

const LanguagePopup: React.FC<LanguagePopupProps> = ({
  currentLanguage,
  isOpen,
  togglePopup,
  languages,
  siteName,
}) => {
  const languageDisplayNames = new Intl.DisplayNames(["en"], {
    type: "language",
  });

  return (
    <div>
      {/* Menu Toggle */}
      <button
        className="text-[14px] text-white hover:text-blue-800 transition duration-300 min-h-[38px] min-w-[38px] h-[38px] w-[38px] bg-black text-center leading-[38px] rounded-full mr-5 uppercase"
        onClick={togglePopup}
      >
        {currentLanguage}
      </button>

      {languages?.length ? (
        <>
          {/* Overlay */}
          <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10 ${
              isOpen ? "block" : "hidden"
            }`}
            onClick={togglePopup}
          ></div>
          {/* Popup */}
          <div
            className={`fixed bottom-0 left-0 w-full bg-white ${
              isOpen ? "translate-y-0" : "translate-y-full"
            } transition-transform duration-300 shadow-2xl rounded-t-lg z-50`}
          >
            <ul className="text-black text-center max-h-[310px] overflow-auto">
              {languages?.map((l: string) => {
                l = l.split("_")[0];
                const displayLanguage = languageDisplayNames.of(l) || l;
                return (
                  <li
                    key={l}
                    className="rounded-md shadow p-2 m-2 hover:bg-gray-100 my-3 "
                  >
                    <a
                      href={`/menus/${siteName}/${l}/`}
                      className="block text-[18px] font-[500] capitalize"
                    >
                      {displayLanguage}
                    </a>
                  </li>
                );
              })}
            </ul>
            <button
              onClick={togglePopup}
              className="block w-[50%] text-black bg-gray-200 hover:bg-gray-300 rounded-full py-3 px-4 my-10 mx-auto"
            >
              Close
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default LanguagePopup;
