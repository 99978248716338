import React, { useState, useEffect } from "react";
// import Image from "next/image";
import { fetchAds } from "@/services/menuService";

const AdsComponent: React.FC<{ menuName: string; country: string }> = ({
  menuName,
  country,
}) => {
  const [ads, setAds] = useState<any>(undefined);

  useEffect(() => {
    // initAds(); Disable because of exceeded vercel quota to save around 50 Gb-Hrs
  }, []);

  const initAds = async () => {
    const response = await fetchAds(menuName, country);
    setAds(response);
  };

  const getFullPath = (fileName: string) =>
    `https://cdn.dopweb.com/qrmenu/ads/ads-files/${fileName}`;

  const renderAdContent = (ad: any) => {
    const fileTypes = {
      image: [".jpeg", ".jpg", ".png", ".webp", ".gif"],
      video: [".mp4"],
    };

    const fileType = Object.entries(fileTypes).find(([type, extensions]) =>
      extensions.some((extension) => ad.fileName?.endsWith(extension))
    )?.[0];

    switch (fileType) {
      case "image":
        return (
          <img
            height="350px"
            width="350px"
            src={getFullPath(ad.fileName)}
            alt={ad.title}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        );
      case "video":
        return (
          <video
            autoPlay
            muted
            loop
            playsInline
            width="100%"
            height="auto"
            title={ad.title}
          >
            <source src={getFullPath(ad.fileName)} type="video/mp4" />
            <track
              src="/captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="English"
            />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return <p>Unsupported ad format</p>;
    }
  };

  return (
    <div className="ads-container">
      {ads && (
        <a
          href={ads.link}
          target="_blank"
          rel="noopener noreferrer"
          className="block md:max-w-[350px] max-w-[90%] mx-auto mt-3"
          aria-label={ads.title}
        >
          {renderAdContent(ads)}
        </a>
      )}
    </div>
  );
};

export default AdsComponent;
