import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Category } from "@/types/types";

interface CategoryItemProps extends Partial<Category> {
  language: string;
}

const CategoryItem: React.FC<Partial<CategoryItemProps>> = ({
  title,
  pageName,
  media,
  siteName,
  language,
}) => {
  const [isLoading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleStart = (url: any) => url !== router.asPath && setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  const ImageSkeleton = () => (
    <div
      className="animate-pulse bg-gray-300 rounded-md"
      style={{ minWidth: "100px", height: "100px" }}
    ></div>
  );

  const ContentSkeleton = () => (
    <div className="animate-pulse flex items-start justify-between pl-4 rounded-lg mb-4 bg-[#E0E0E0] mx-[5%] min-h-[100px]">
      <div className="w-full">
        <div className="bg-gray-300 rounded h-5  mr-4 mt-3"></div>

        <div className="bg-gray-300 rounded h-10  mt-4 mr-4"></div>
      </div>
      <ImageSkeleton />
    </div>
  );

  const imageUrl = (media?.src || "")
    .replace("https://qrmenu.com", "")
    .replace("/menus", "");

  return (
    <>
      {isLoading ? (
        <ContentSkeleton />
      ) : (
        <Link
          href={`/menus/${siteName}/${pageName}/${language}`}
          key={title}
          className="flex items-center justify-between pl-4 rounded-lg mb-4 bg-[#F1F3F5] mx-[5%] min-h-[100px] relative overflow-hidden"
        >
          <h2
            className={`font-[500] text-[16px] pr-3 ${
              imageUrl ? "line-clamp-2 md:line-clamp-4" : ""
            }`}
          >
            {title}
          </h2>
          {imageUrl ? (
            <>
              <ImageSkeleton />
              <img
                src={`${process.env.NEXT_PUBLIC_QR_CDN}${imageUrl}`}
                alt={siteName + " - " + title}
                width="100px"
                height="100px"
                className="rounded-tr-md rounded-br-md absolute"
                style={{ top: 0, right: 0 }}
              />
            </>
          ) : null}
        </Link>
      )}
    </>
  );
};

export default CategoryItem;
