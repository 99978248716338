import React, { useEffect, useState } from "react";
import AdSenseAd from "@/components/google-adSense/AdSenseAd";
import { Product, VariantType } from "@/types/types";
import AdsComponent from "../ads-component/AdsComponent";
import * as gtag from "@/lib/gtag";
import cloneDeep from "lodash/cloneDeep";
import CheckIcon from "../icons/CheckIcon";
import PlusIcon from "../icons/PlusIcon";
import { useTranslation } from "@/hooks/useTranslation";

interface ItemProduct extends Partial<Product> {
  country?: string;
  language: string;
  subscribed?: boolean;
  selectedVariant?: string;
  selectedAdditions?: string;
  openProduct: boolean;
}

const LoadingSkeleton = ({
  height,
  width,
}: {
  height: string;
  width: string;
}) => (
  <div
    className="animate-pulse bg-gray-300 rounded-md"
    style={{ minWidth: width, height: height }}
  ></div>
);

const ProductItem: React.FC<ItemProduct> = ({
  itemId,
  title,
  description,
  media,
  price,
  siteName,
  country,
  language,
  subscribed,
  onlinePrice,
  variants,
  additions,
  onlineStore,
  variantTypes: ProductVariantTypes,
  currency,
  openProduct,
}) => {
  const { translate } = useTranslation(
    ["ar", "fr"].includes(language) ? language : "en"
  );
  const [variantTypes, setVariantTypes] = useState<any>(
    ProductVariantTypes?.filter((v: any) => v.type)
  );
  const [selectedAdditions, setSelectedAdditions] = useState<string[]>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [instructions, setInstructions] = useState<string>("");

  const getSelectedVariantNames = (variantTypes: VariantType[] | undefined) => {
    return (
      variantTypes
        ?.map((variant) => {
          const selectedOption = variant.options.find(
            (option) => option?.selected
          );
          return selectedOption ? selectedOption.name : null;
        })
        .filter(Boolean)
        .join(" / ") || ""
    );
  };

  const isSameProduct = (product1: ItemProduct, product2: ItemProduct) => {
    return (
      product1.selectedVariant === product2.selectedVariant &&
      product1.selectedAdditions === product2.selectedAdditions &&
      product1.itemId === product2.itemId
    );
  };

  const updateCartWithQuantity = (newProduct: ItemProduct) => {
    const cart = JSON.parse(localStorage.getItem(`cart-${siteName}`) || "[]");
    let productExists = false;

    for (const cartItem of cart) {
      if (isSameProduct(cartItem, newProduct)) {
        cartItem.quantity += newProduct.quantity;
        productExists = true;
        break;
      }
    }

    if (!productExists) {
      cart.push(newProduct);
    }

    localStorage.setItem(`cart-${siteName}`, JSON.stringify(cart));
    return cart;
  };

  const createCartItem = (
    product: ItemProduct,
    quantity: number,
    totalPrice: number,
    onlinePrice: number
  ) => {
    return { ...product, quantity, totalPrice, onlinePrice };
  };

  const dispatchCartUpdateEvent = (updatedCart: any) => {
    const event = new CustomEvent("cartUpdated", { detail: updatedCart });
    window.dispatchEvent(event);
  };

  const addToCart = (product: ItemProduct, quantity: number) => {
    const selectedVariantNames = getSelectedVariantNames(product.variantTypes);
    const newCartItem = createCartItem(
      { ...product, selectedVariant: selectedVariantNames },
      quantity,
      getTotalPrice(true), // with quantity
      getTotalPrice(false) // without quantity
    );
    const updatedCart = updateCartWithQuantity(newCartItem as any);
    dispatchCartUpdateEvent(updatedCart);
  };

  const handleAddToCart = () => {
    const productDetails = {
      itemId,
      title,
      description,
      media,
      price,
      siteName,
      country,
      onlinePrice,
      variants,
      additions,
      onlineStore,
      variantTypes,
      currency,
      selectedAdditions: selectedAdditions.join(", "),
      instructions,
      // other product details if needed
    } as any;

    addToCart({ ...productDetails }, quantity);
    setQuantity(1);
    setSelectedAdditions([]);
    resetVariants();
    togglePopup();
    // Optionally, do something after adding to the cart
  };

  const resetVariants = () => {
    variantTypes.forEach((v: any) => {
      if (v) {
        v.options = v.options.map((option: any, index: number) => {
          if (option) {
            option.selected = index === 0;
          }
          return option;
        });
      }
    });

    setVariantTypes(cloneDeep([...variantTypes]));
  };

  const handleVariantChange = (variantType: string, optionName: string) => {
    const v = variantTypes.find((v: any) => {
      return v.type === variantType;
    });
    if (v) {
      v.options = v.options.map((option: any) => {
        if (option) {
          option.selected = option.name === optionName;
        }
        return option;
      });
    }
    setVariantTypes(cloneDeep([...variantTypes]));
  };

  const handleAdditionChange = (addition: string) => {
    const updatedAdditions = [...selectedAdditions];
    const index = updatedAdditions.findIndex((a) => a === addition);

    if (index === -1) {
      updatedAdditions.push(addition);
    } else {
      updatedAdditions.splice(index, 1);
    }

    setSelectedAdditions(cloneDeep([...updatedAdditions]));
  };

  const getTotalPrice = (withQuantity: boolean) => {
    // Calculate the total price based on selected variants and additions
    let newTotalPrice = 0;
    let selectedVariant;
    let selectedVariantNames = variantTypes
      .map((variant: any) => {
        const selectedOption = variant.options.find(
          (option: any) => option?.selected
        );
        return selectedOption ? selectedOption.name : null;
      })
      .filter(Boolean);
    let selectedVariantsSlot = "";
    if (selectedVariantNames.length) {
      selectedVariantsSlot = selectedVariantNames.join(" / ");
      selectedVariant = variants?.find((v) =>
        selectedVariantsSlot.includes(v.name)
      );
    }
    if (selectedVariant) {
      newTotalPrice += parseFloat(selectedVariant.price.toString() || "0");
    } else {
      newTotalPrice += onlinePrice || 0;
    }

    additions &&
      additions.forEach((addition) => {
        if (selectedAdditions.includes(addition.name || "")) {
          newTotalPrice += addition.price
            ? parseFloat(addition.price.toString())
            : 0;
        }
      });

    if (withQuantity) {
      newTotalPrice = newTotalPrice * quantity;
      return newTotalPrice;
    }

    return newTotalPrice;
  };

  const [isPopupOpen, setIsPopupOpen] = useState(openProduct || false);

  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("modal-open");
      gtag.event(
        "action",
        {
          event_label: "Show product Event",
          value: title || `Product title empty for: ${siteName}`,
          event_category: "engagement",
        },
        siteName!
      );
      const elGoogle = document.querySelector(
        '[aria-label="Close"]'
      ) as HTMLElement;
      if (elGoogle) {
        elGoogle.click();
      }
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen]);

  const formatText = (text: string): string => {
    const cappedText =
      text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    return cappedText;
  };

  const togglePopup = () => {
    if (!description && !price) {
      return;
    }
    setIsPopupOpen(!isPopupOpen);
  };

  const cappedTitle = formatText(title || "");
  const cappedDescription = formatText(description || "");

  const imageUrl = (media?.src || "")
    .replace("https://qrmenu.com", "")
    .replace("/menus", "");

  const formatTotalPrice = (price: number) => {
    return currency === "$"
      ? `${currency}${price.toFixed(2)}`
      : `${price.toFixed(2)}${currency}`;
  };

  return (
    <div key={cappedTitle}>
      <div
        onClick={togglePopup}
        className={`flex items-center justify-between pl-4 rounded-lg mb-4  mx-[5%]  cursor-pointer relative ${
          !description && !price
            ? "h-[75px] bg-[#000] text-[#fff]"
            : "min-h-[100px] bg-[#F1F3F5]"
        }`}
      >
        <div className="py-3 overflow-hidden">
          <h3
            id={itemId}
            className={`font-[600] text-[15px] pr-3 leading-[18px] uppercase ${
              imageUrl ? "line-clamp-2 md:line-clamp-4" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: cappedTitle }}
          />

          {cappedDescription && (
            <p
              className={`text-[14px] pr-3 leading-[15px] mt-2 ${
                imageUrl ? "line-clamp-2 md:line-clamp-4" : ""
              }`}
              dangerouslySetInnerHTML={{ __html: cappedDescription }}
            />
          )}

          {price && price !== "0" && price !== "" && (
            <>
              <hr className="mt-2 bg-black w-[90%]" />
              <span className="font-bold text-[12px] pr-3">{price}</span>
            </>
          )}
        </div>
        {imageUrl && (
          <>
            <LoadingSkeleton
              height={!description && !price ? "75px" : "129px"}
              width={!description && !price ? "75px" : "129px"}
            />
            <img
              src={`${
                !imageUrl.includes("cdn.qrmenu")
                  ? process.env.NEXT_PUBLIC_QR_CDN
                  : ""
              }${imageUrl}`}
              alt={siteName + " - " + title}
              width={`${!description && !price ? 75 : 129}px`}
              height={`${!description && !price ? 75 : 129}px`}
              className={`rounded-tr-md rounded-br-md absolute object-cover`}
              style={{
                top: 0,
                right: 0,
                height: !description && !price ? 75 : 129,
              }}
            />
          </>
        )}
      </div>

      {isPopupOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 overflow-scroll flex justify-center items-start md:items-center z-50 full-screen"
          onClick={togglePopup}
        >
          <div
            className="relative bg-white shadow-xl overflow-scroll min-h-[100vh] md:min-h-[50vh] md:max-w-lg md:max-h-[95vh] w-full p-0 md:rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={togglePopup}
              className="fixed md:absolute top-2 left-5 text-xl text-white bg-white rounded-full h-66 w-66 shadow-lg z-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="3 0 48 48"
                fill="none"
              >
                <path
                  d="M18 14L38 34M18 34L38 14"
                  stroke="black"
                  strokeWidth="1.5625"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {imageUrl ? (
              <img
                src={`${process.env.NEXT_PUBLIC_QR_CDN}${imageUrl}`}
                alt={title || "Product"}
                width="129px"
                height="129px"
                className="w-full max-h-[350px] md:max-h-[512px] object-cover"
                style={{ top: 0, right: 0 }}
              />
            ) : (
              <div className="mt-[70px]"></div>
            )}
            <div className={`p-4 ${subscribed ? "pb-4" : "pb-24"}`}>
              <h2 className="text-[20px] font-bold mb-2">{title}</h2>
              <p
                className="text-[16px] mb-4"
                dangerouslySetInnerHTML={{
                  __html:
                    description?.replaceAll(/\n{3,}/g, "<br/><br/>") || "",
                }}
              ></p>
              <p className="text-[14px] mb-2 mt-5 font-[600]">
                {onlineStore ? formatTotalPrice(getTotalPrice(true)) : price}
              </p>
              {variantTypes && variantTypes.length > 0 ? (
                <>
                  <div className="text-[18px] font-bold mb-2 mt-5">
                    {translate("variants")}
                  </div>
                  {variantTypes.map((variant: any, index: number) => (
                    <div key={variant.type + index} className="mb-3">
                      <label className="text-[17px] font-[600] capitalize">
                        {variant.type}
                      </label>
                      <div className="grid grid-cols-1 gap-2 mt-2">
                        {!!variant.options?.length &&
                          variant.options.map(
                            (option: any, optionIndex: number) => (
                              <label
                                key={`options-${optionIndex}`}
                                htmlFor={`${variant.type}-${option.name}`}
                                className={`flex items-center justify-between me-4 mt-2 w-full ${
                                  option.selected ? "" : "opacity-50"
                                }`}
                              >
                                <span className="text-[16px] font-medium text-gray-900">
                                  {option.name}
                                </span>
                                <input
                                  id={`${variant.type}-${option.name}`}
                                  type="radio"
                                  name={variant.type}
                                  value={option.name}
                                  checked={option.selected}
                                  onChange={(e) =>
                                    handleVariantChange(
                                      variant.type,
                                      e.target.value
                                    )
                                  }
                                  className="hidden"
                                />
                                {option.selected ? <CheckIcon /> : <PlusIcon />}
                              </label>
                            )
                          )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                ""
              )}

              {additions && additions.length > 0 && (
                <>
                  <div className="text-[18px] font-bold mb-2 mt-5">
                    {translate("additions")}
                  </div>
                  <div className="grid grid-cols-1 gap-2">
                    {additions.map((addition) => (
                      <div
                        key={addition.name}
                        className="flex items-center justify-between mb-2 cursor-pointer overflow-hidden w-full"
                        onClick={() =>
                          handleAdditionChange(addition.name || "")
                        }
                      >
                        <label
                          htmlFor={addition.name || ""}
                          className="text-[16px] cursor-pointer"
                        >
                          {addition.name}
                        </label>

                        {selectedAdditions.includes(addition.name || "") ? (
                          <CheckIcon />
                        ) : (
                          <PlusIcon />
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}

              {onlineStore && (
                <textarea
                  rows={4}
                  placeholder={translate("add_instructions")}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                  className="border border-radius-[3px] w-full p-2 mt-4 text-[16px]"
                  style={{ borderRadius: "3px" }}
                ></textarea>
              )}

              {onlineStore && (
                <div className="mx-auto max-w-[223px]">
                  <div className={`relative quantity_container`}>
                    <button
                      onClick={() => {
                        setQuantity(Number(quantity) - 1);
                      }}
                      className={`ml-3 ${
                        quantity < 2 ? "pointer-events-none	opacity-0" : ""
                      }`}
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22"
                          cy="22"
                          r="22"
                          fill="var(--foreground-color, #000)"
                        />
                        <path
                          d="M9.6624 20.6047H34.3374C34.5332 20.6047 34.6312 20.7027 34.6312 20.8985V23.1016C34.6312 23.2974 34.5332 23.3954 34.3374 23.3954H9.6624C9.46657 23.3954 9.36865 23.2974 9.36865 23.1016V20.8985C9.36865 20.7027 9.46657 20.6047 9.6624 20.6047Z"
                          fill="var(--text-color, #fff)"
                        />
                      </svg>
                    </button>

                    <input
                      type="number"
                      value={quantity}
                      style={{ maxWidth: "280px" }}
                      onChange={(e) => {
                        if (Number(e.target.value)) {
                          setQuantity(Number(e.target.value));
                        }
                      }}
                    />
                    <button
                      onClick={() => {
                        setQuantity(Number(quantity) + 1);
                      }}
                      className={`mr-3`}
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22"
                          cy="22"
                          r="22"
                          fill="var(--foreground-color, #000)"
                        />
                        <path
                          d="M20.8984 8.78125H23.1015C23.2973 8.78125 23.3952 8.87917 23.3952 9.075V34.925C23.3952 35.1208 23.2973 35.2187 23.1015 35.2187H20.8984C20.7025 35.2187 20.6046 35.1208 20.6046 34.925V9.075C20.6046 8.87917 20.7025 8.78125 20.8984 8.78125Z"
                          fill="var(--text-color, #fff)"
                        />
                        <path
                          d="M9.6624 20.6047H34.3374C34.5332 20.6047 34.6312 20.7027 34.6312 20.8985V23.1016C34.6312 23.2974 34.5332 23.3954 34.3374 23.3954H9.6624C9.46657 23.3954 9.36865 23.2974 9.36865 23.1016V20.8985C9.36865 20.7027 9.46657 20.6047 9.6624 20.6047Z"
                          fill="var(--text-color, #fff)"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              <hr className="mt-3 bg-black w-full" />

              {!subscribed && (
                <div>
                  {/* Aff ads */}
                  {siteName ? (
                    <AdsComponent menuName={siteName} country={country || ""} />
                  ) : (
                    <></>
                  )}
                  {/* Google ads */}
                  {!subscribed ? (
                    <div className="mt-5 mb-12">
                      <AdSenseAd
                        adClient="ca-pub-9101961507466056"
                        adSlot="9338909219"
                        style={{
                          width: "100%",
                          height: "300px",
                        }} // Square dimensions
                        format="auto"
                        fullWidthResponsive="false"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="mb-24 mt-10"></div>
                </div>
              )}
              <div className="fixed md:relative bottom-0 left-0 w-full">
                <div className="w-full border-t border-solid border-[#d9dbe080] flex p-5 justify-center font-roboto bg-white  md:max-w-lg mx-auto">
                  {onlineStore ? (
                    <button
                      onClick={handleAddToCart}
                      className="h-[48px] text-white w-[312px] bg-green-500 px-[20px] py-[10px] rounded-[30px] m-[0 10px] cursor-pointer"
                    >
                      <span className="inline-block">{translate("add")}</span>{" "}
                      <span className="inline-block">
                        {" "}
                        <strong>{quantity}</strong>
                      </span>{" "}
                      <span className="inline-block">{translate("for")}</span>{" "}
                      <span className="inline-block">
                        <strong>{formatTotalPrice(getTotalPrice(true))}</strong>
                      </span>
                    </button>
                  ) : (
                    <button
                      onClick={togglePopup}
                      className={`block w-full mx-auto bg-black text-white px-3 py-3 rounded-md shadow text-[13px] max-w-[93%] z-80 ${
                        subscribed ? "mt-4" : ""
                      }`}
                    >
                      {translate("close")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductItem;
