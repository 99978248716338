// components/CategoryList.tsx
import React from "react";
import CategoryItem from "./CategoryItem";
import { Category } from "@/types/types";
import AdSenseAd from "../google-adSense/AdSenseAd";

interface CategoryListProps {
  categories: Category[];
  siteName: string;
  language: string;
  subscribed: boolean;
  showCategories: boolean;
}

const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  siteName,
  language,
  subscribed,
  showCategories,
}) => {
  return (
    <div className="mt-5">
      {categories?.map((category, index: number) => (
        <div key={category.title}>
          <CategoryItem
            key={category.title}
            title={category.title}
            pageName={category.pageName}
            media={category.media}
            siteName={siteName}
            language={language}
          />
        </div>
      ))}
    </div>
  );
};

export default CategoryList;
