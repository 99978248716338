import { useTranslation } from "@/hooks/useTranslation";
import React, { useState, useEffect } from "react";

type CartItem = {
  title: string;
  totalPrice: number;
  quantity: number;
  selectedVariant: string;
  selectedAdditions: string;
  instructions: string;
};

interface Cart {
  siteName: string;
  currency: string;
  language: string;
}

const Cart: React.FC<Cart> = ({ siteName, currency, language }) => {
  const { translate } = useTranslation(
    ["ar", "fr"].includes(language) ? language : "en"
  );

  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [showCart, setShowCart] = useState<boolean>(false);

  useEffect(() => {
    const handleCartUpdate = (event: any) => {
      setCartItems(event.detail);
    };

    window.addEventListener("cartUpdated", handleCartUpdate);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, []);

  useEffect(() => {
    if (showCart && cartItems.length) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [showCart, cartItems]);

  const toggleCart = () => setShowCart(!showCart);

  useEffect(() => {
    // Load cart items from session storage
    const storedCart = localStorage.getItem(`cart-${siteName}`);
    if (storedCart) {
      setCartItems(JSON.parse(storedCart) as CartItem[]);
    }
  }, [siteName]);

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.totalPrice * item.quantity,
    0
  );

  const removeItem = (index: number) => {
    const newCartItems = cartItems.filter((item, i) => i !== index);

    setCartItems(newCartItems);
    localStorage.setItem(`cart-${siteName}`, JSON.stringify(newCartItems));
    if (!newCartItems.length) {
      setShowCart(false);
    }
  };

  const formatTotalPrice = (price: string) => {
    return currency === "$" ? `${currency}${price}` : `${price}${currency}`;
  };

  if (cartItems.length === 0) return <></>;

  const getTotalQuantityCartItems = () => {
    return cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
  };

  return (
    <div className="fixed bottom-0 right-0 p-4 z-50 w-full">
      <button
        onClick={toggleCart}
        className="bg-green-500 h-[48px] text-white p-2 rounded-full shadow-md relative w-full px-3  md:max-w-lg mx-auto block"
      >
        <span className="inline-block">{translate("order")}</span>{" "}
        <span className="inline-block">
          <strong>{getTotalQuantityCartItems()}</strong>
        </span>{" "}
        {translate("for")}{" "}
        <span className="inline-block">
          <strong> {formatTotalPrice(totalPrice.toFixed(2))}</strong>
        </span>
      </button>
      {/* Overlay */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10 ${
          showCart ? "block" : "hidden"
        }`}
        onClick={toggleCart}
      ></div>
      {/* Popup */}
      <div
        className={`fixed bottom-0 left-0 w-full h-full bg-white ${
          showCart ? "translate-y-0" : "translate-y-full"
        } transition-transform duration-300 shadow-2xl z-50 overflow-hidden`}
      >
        {" "}
        <div className="relative mx-auto p-5 w-full bg-white full-screen">
          <div className="flex justify-between items-center">
            <div className="mt-8">
              <h2 className="text-lg font-semibold">
                {translate("your_order")}
              </h2>
              <span className="text-xs">
                {cartItems.length} {translate("products")}
              </span>
            </div>
            <button
              className="px-3 py-3 rounded flex items-center bg-gray-200 rounded-full"
              onClick={toggleCart}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>{" "}
          <div className="overflow-x-auto mt-3 max-h-[550px] overflow-y">
            <table className="min-w-full bg-white text-[13px] text-center">
              <tbody>
                {cartItems.map((item, index) => (
                  <tr key={`first_${index}`} className="text-[16px]">
                    <td className="pt-3 px-4 font-bold flex flex-col items-center">
                      <span>{item.quantity}x</span>
                      <button
                        onClick={() => {
                          if (item.quantity >= 2) {
                            item.quantity = item.quantity - 1;
                            setCartItems([...cartItems]);
                            localStorage.setItem(
                              `cart-${siteName}`,
                              JSON.stringify(cartItems)
                            );
                          } else {
                            removeItem(index);
                          }
                        }}
                        className="text-red-500 hover:text-red-700 mt-3"
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          width="31"
                          height="33"
                          viewBox="0 0 31 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="15.2628"
                            cy="16.0259"
                            rx="15.2628"
                            ry="16.0259"
                            fill="#E9FFF2"
                          />
                          <path
                            d="M21.8039 17.3996H8.72156V15.568H21.8039V17.3996Z"
                            fill="#03BA4C"
                          />
                        </svg>
                      </button>
                    </td>
                    <td className="pt-3 w-full align-top">
                      <div className="flex flex-col items-start justify-start text-left">
                        <span>{item.title}</span>
                        <span className="text-gray-400 lowercase">
                          {item.selectedVariant ? (
                            <>
                              {item.selectedVariant}
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {item.selectedAdditions ? (
                            <>
                              {item.selectedAdditions}
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {item.instructions ? ` (${item.instructions})` : ""}
                        </span>
                      </div>
                    </td>
                    <td className="pt-3 px-4  flex flex-col items-center">
                      <span>
                        {(item.quantity * item.totalPrice).toFixed(2)}
                      </span>
                      <button
                        onClick={() => {
                          item.quantity = item.quantity + 1;
                          setCartItems([...cartItems]);
                          localStorage.setItem(
                            `cart-${siteName}`,
                            JSON.stringify(cartItems)
                          );
                        }}
                        className="text-red-500 hover:text-red-700 mt-3"
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          width="31"
                          height="33"
                          viewBox="0 0 31 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="15.2628"
                            cy="16.0259"
                            rx="15.2628"
                            ry="16.0259"
                            fill="#E9FFF2"
                          />
                          <path
                            d="M22.427 16.7455H16.8203V22.6325C16.8203 22.9448 16.7021 23.2443 16.4918 23.4651C16.2815 23.6859 15.9963 23.8099 15.6989 23.8099C15.4015 23.8099 15.1163 23.6859 14.906 23.4651C14.6957 23.2443 14.5776 22.9448 14.5776 22.6325V16.7455H8.97083C8.67343 16.7455 8.38822 16.6214 8.17792 16.4006C7.96763 16.1798 7.84949 15.8803 7.84949 15.568C7.84949 15.2558 7.96763 14.9563 8.17792 14.7355C8.38822 14.5147 8.67343 14.3906 8.97083 14.3906H14.5776V8.50355C14.5776 8.19128 14.6957 7.8918 14.906 7.67099C15.1163 7.45019 15.4015 7.32614 15.6989 7.32614C15.9963 7.32614 16.2815 7.45019 16.4918 7.67099C16.7021 7.8918 16.8203 8.19128 16.8203 8.50355V14.3906H22.427C22.7244 14.3906 23.0096 14.5147 23.2199 14.7355C23.4302 14.9563 23.5484 15.2558 23.5484 15.568C23.5484 15.8803 23.4302 16.1798 23.2199 16.4006C23.0096 16.6214 22.7244 16.7455 22.427 16.7455Z"
                            fill="#03BA4C"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="font-bold text-right mt-5">
            <span className="inline-block">{translate("total")}</span>:{" "}
            <span className="inline-block">
              {formatTotalPrice(totalPrice.toFixed(2))}
            </span>
          </div>
          <div className="bg-white w-full fixed bottom-0 left-0 px-5 py-8">
            <a
              href={`/menus/checkout/?menu=${siteName}`}
              className="flex justify-center items-center h-[56px] text-white bg-green-500 px-[20px] py-[10px] rounded-[30px] mx-auto mt-5 font-roboto cursor-pointer"
            >
              {translate("proceed_to_checkout")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
