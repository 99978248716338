import React from "react";

const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#00A082" />
      <path
        d="M16.085 7.5322C16.4141 7.13712 17.0011 7.08362 17.3962 7.4127C17.7649 7.71984 17.8361 8.2517 17.5764 8.64246L17.5157 8.72391L11.0656 16.4677C10.737 16.8621 10.1574 16.9122 9.76726 16.5979L9.69188 16.5302L6.54124 13.3795C6.17766 13.0159 6.17766 12.4265 6.54124 12.0629C6.88058 11.7235 7.41671 11.7009 7.78229 11.995L7.85789 12.0629L10.2869 14.4921L16.085 7.5322Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckIcon;
