// components/Footer.tsx
import React from "react";
import Image from "next/image";
import Script from "next/script";

interface props {
  siteName: string;
  loadAds: boolean;
}

const Footer: React.FC<props> = ({ siteName, loadAds }) => {
  return (
    <footer className="mt-auto bg-black shadow-inner">
      <a
        href={`https://qrmenu.dopweb.com/dashboard/home?site=${siteName}`}
        className="flex items-center justify-center h-[148px] w-full text-white flex-col"
      >
        <img
          alt="QRcode qrmenu"
          src="/images/qrcode.webp"
          width="48px"
          height="48px"
        />
        <span className="text-[13px] font-[600] mt-2">QRmenu.com</span>
      </a>
      {loadAds ? (
        <Script
          async
          data-ad-client="ca-pub-9101961507466056"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          strategy="lazyOnload"
        />
      ) : (
        <></>
      )}
    </footer>
  );
};

export default Footer;
