// components/ProductList.tsx
import React, { useEffect } from "react";
import ProductItem from "./ProductItem";
import { Product } from "@/types/types";
import { useRouter } from "next/router";
import AdSenseAd from "../google-adSense/AdSenseAd";

interface ProductListProps {
  products: Partial<Product>[];
  country: string;
  language: string;
  subscribed: boolean;
}

const ProductList: React.FC<ProductListProps> = ({
  products,
  country,
  language,
  subscribed,
}) => {
  const router = useRouter();
  const productId = router.query.product_id;
  useEffect(() => {
    const setHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setHeight);

    setInterval(() => {
      setHeight();
    }, 3000);

    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  return (
    <div className="mt-5">
      {products?.map((product, index: number) => (
        <div key={product.title}>
          <ProductItem
            key={product.title}
            itemId={product.itemId}
            openProduct={product.itemId === productId}
            title={product.title}
            description={product.description}
            media={product.media}
            price={product.formattedPrice || ""}
            siteName={product.siteName}
            country={country}
            language={language}
            subscribed={subscribed}
            onlinePrice={product.onlinePrice}
            onlineStore={product.onlineStore}
            additions={product.additions}
            variants={product.variants}
            variantTypes={product.variantTypes}
            currency={product.currency}
          />
        </div>
      ))}
    </div>
  );
};

export default ProductList;
