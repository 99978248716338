import { Menu } from "@/types/types";
import React from "react";

interface RestaurantSchemaProps {
  menuData: Menu;
}

const RestaurantSchema: React.FC<RestaurantSchemaProps> = ({ menuData }) => {
  const { siteName, logo, description, currency, companyInfo } = menuData;
  const object = {
    "@context": "http://schema.org",
    "@id": process.env.NEXT_PUBLIC_URL + "/menus/" + siteName,
    "@type": "Restaurant",
    name: siteName,
    image: [logo],
    description: description,
    address: {
      "@type": "PostalAddress",
      streetAddress: companyInfo?.address,
    },
    telephone: companyInfo?.phoneNumber,
    url: companyInfo?.website,
    priceRange: currency,
    servesCuisine: menuData.categories.map((c) => c.title),
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(object),
      }}
    />
  );
};

export default RestaurantSchema;
